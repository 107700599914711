'use client';

import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import Toast from "@/components/toast-handler/toast";
import { AnimatePresence } from 'framer-motion';
import { LenisContext } from '@/context/lenis-context';
import { v4 as uuidv4 } from 'uuid';

export type ToastType = {
    id: string,
    message: string;
    statusCode: number;
    type: 'success' | 'error' | 'info' | 'warning';
};

const testToasts: ToastType[] = [
    {
        id: uuidv4(),
        message: "Autologin redirect successful.",
        statusCode: 500,
        type: 'success'
    },
    {
        id: uuidv4(),
        message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, quisquam?",
        statusCode: 500,
        type: 'info'
    },
    {
        id: uuidv4(),
        message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, quisquam?",
        statusCode: 500,
        type: 'warning'
    },
    {
        id: uuidv4(),
        message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, quisquam?",
        statusCode: 500,
        type: 'error'
    }
];


export default function ToastContainer()
{
    // context
    const lenisContext = useContext(LenisContext);

    // state
    const [toasts, setToasts] = useState<ToastType[]>(lenisContext.requestToasts);

    // use ref
    const isMounted = useRef(false);
    const debug = useRef(false);

    // root effect
    useEffect(() =>
    {
        // ANCHOR Root Effect

        isMounted.current = true;

        // initialize the context toasts here
        if (debug.current)
            lenisContext.initializeToasts(testToasts);

        return () =>
        {
            isMounted.current = false;
        };

    }, []);

    // update the  when the context changes
    useEffect(() =>
    {
        setToasts(lenisContext.requestToasts);
    }, [lenisContext.requestToasts]);


    return (
        <div className="toast-container">
            <AnimatePresence mode='popLayout'> {/* popLayout automatically passes ref to Toast */ }
                {
                    toasts.map((toast, index) =>
                    {
                        return (
                            <Toast
                                key={ `toast-${ toast.id }` }
                                id={ toast.id }
                                message={ toast.message }
                                type={ toast.type }
                                statusCode={ toast.statusCode }
                            />
                        );
                    })
                }
            </AnimatePresence >
        </div>
    );
}